import * as React from 'react';
import Directors from '../../components/Board/Directors';
import Layout from '../../components/Layout/index';
import { Seo } from '../../components/Seo';

const BoardPage = () => {
  return (
    <Layout>
      <Seo
        title="Board Of Directors | National Embryo Donation Center"
        description="The Board of Directors oversees the NEDC with Medical Director Dr. Jeffrey Keenan helping infertile couples through the donation process"
      />
      {/* <Header
        title="Board of Directors"
        
      /> */}
      <Directors />
    </Layout>
  );
};

export default BoardPage;
